import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import RepoCard from 'components/RepoCard'
import PostsPagination from 'components/PostsPagination'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { StaticImage } from 'gatsby-plugin-image'
import PageTitle from 'components/PageTitle'

export const query = graphql`
  query AllMarkdownRemark($limit: Int!, $skip: Int!, $templateKey: String!, $templatePath: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allMarkdownRemark(
      sort: { frontmatter: { publishedAt: DESC } }
      filter: { frontmatter: { templateKey: { eq: $templateKey }, path: { eq: $templatePath } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            topics
            description
            keywords
          }
        }
      }
    }
  }
`

const BlogList = ({ data, location, pageContext }) => {
  const { currentPage, prefixPath, hasNextPage, hasPreviousPage, itemCount, perPage, totalCount } = pageContext
  const previousPageCount = currentPage - 1
  const prevPage =
    hasPreviousPage && previousPageCount > 1 ? `${prefixPath}/${(currentPage - 1).toString()}` : prefixPath
  const nextPage = `${prefixPath}/${(currentPage + 1).toString()}`
  const type = pageContext.type
  const titleType = type + '.title'

  const posts = data.allMarkdownRemark.edges.map(({ node }) => node)
  const postsLocale = posts

  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            isBlogPost={false}
            title={formatMessage({ id: titleType.toString() })}
            description={formatMessage({ id: 'meta.description' })}
            keywords={formatMessage({ id: 'meta.keywords' })}
            url={formatMessage({ id: 'meta.url.blog' })}
          />
          <section className='section is-size-4-desktop is-size-5-touch'>
            <PageTitle pageTitle={<FormattedMessage id={titleType.toString()} />} />
            <div className='container'>
              <hr />
              <div className='columns'>
                <div className='column is-half is-offset-one-quarter'>
                  <>
                    {postsLocale.map(post => {
                      let topics = post.frontmatter.topics.map(node => node).slice(0, 3)
                      return (
                        <RepoCard
                          key={post.fields.slug}
                          title={post.frontmatter.title}
                          subtitle={post.frontmatter.description.substring(0, 100)}
                          link={`${post.fields.slug}`}
                          image={
                            <StaticImage
                              src='../../static/img/mihirpipermitwala-placeholder.jpg'
                              alt={data.site.siteMetadata.siteUrl + post.fields.slug}
                              layout='constrained'
                              width='200px'
                              style={{ borderRadius: '0.8em', border: '1em' }}
                              aspectRatio={1.5}
                              transformOptions={{
                                fit: 'cover',
                                cropFocus: 'attention',
                              }}
                              backgroundColor='#181818'
                            />
                          }
                          topics={topics}
                        />
                      )
                    })}
                  </>
                </div>
              </div>
              <PostsPagination
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />

              <p className='has-text-centered'>
                <FormattedMessage id='checkOut' />{' '}
                {type == 'blogs' && (
                  <Link to={getPath('tutorials', locale)}>
                    <FormattedMessage id='tutorials.title' />
                  </Link>
                )}
                {type == 'tutorials' && (
                  <Link to={getPath('blogs', locale)}>
                    <FormattedMessage id='blogs.title' />
                  </Link>
                )}
                {'.'}
              </p>
            </div>
          </section>
        </Layout>
      )}
    </IntlProvider>
  )
}

BlogList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogList
